
    !(async () => {
      const productSkus = new URLSearchParams(location.search).get("customer_product_skus")
      const widget = document.querySelector("tint-vto")

      let decodedProductSkus = decodeURIComponent(productSkus)

      try {
        decodedProductSkus = JSON.parse(decodedProductSkus)
      } catch (_) {
        decodedProductSkus = decodedProductSkus.split(",")
      }

      //const existing = await widget.isCustomerSkuExist(decodedProductSkus)

      // console.debug("[ API ] isCustomerSkuExist method result", existing)

      await widget.open()

      if (decodedProductSkus) widget.applyProductByCustomerSku(decodedProductSkus)

      const updateUrlWithSkus = (products) => {
        let productSkus = []

        for (let i = 0; i < products.length; ++i) {
          if (products[i].skus[0].sku) productSkus.push(products[i].skus[0].sku)
        }

        const skusQueryParams = productSkus[0]
        const urlParams = new URLSearchParams(location.search)

        urlParams.set("customer_product_skus", skusQueryParams)
        const baseUrl = window.location.href.split("?")[0]

        const newUrl = `${baseUrl}?${urlParams.toString()}`
        window.history.pushState({ path: newUrl }, "", newUrl)
      }

      widget.addEventListener("applyProduct", ({ detail }) => {
        console.debug("[ API ] applyProduct event fired", JSON.stringify(detail, null, 2))
        updateUrlWithSkus(detail)
      })

      widget.addEventListener("removeAppliedProduct", ({ detail }) => {
        console.debug("[ API ] removeAppliedProduct event fired", JSON.stringify(detail, null, 2))
        updateUrlWithSkus(detail)
      })

      widget.addEventListener("replaceAppliedProduct", ({ detail }) => {
        console.debug(
          "[ API ] replaceAppliedProduct event fired",
          JSON.stringify(detail, null, 2)
        )
        // updateUrlWithSkus(detail)
      })

      widget.addEventListener("redirectToCart", () =>
        console.debug("[ API ] redirectToCart event fired")
      )

      widget.addEventListener("addToCart", ({ detail }) =>
        console.debug("[ API ] addToCart event fired", JSON.stringify(detail, null, 2))
      )

      widget.addEventListener("removeFromCart", ({ detail }) =>
        console.debug("[ API ] removeFromCart event fired", JSON.stringify(detail, null, 2))
      )

      widget.addEventListener("analysisFinished", ({ detail }) =>
        console.debug("[ API ] analysisFinished event fired", JSON.stringify(detail, null, 2))
      )

      // await widget.useWebcam().then((wcam) => {
      //   if (!wcam)
      //     fetch("model.webp")
      //       .then((r) => r.blob())
      //       .then((b) => widget.usePhoto(b))
      // })
    })()
  